import React, { useState } from 'react';
import axios from 'axios';
import {Player} from 'react-simple-player';
import { Triangle } from 'react-loader-spinner';
import CssBaseline from '@mui/material/CssBaseline';
import './App.css';
import {Button, NativeSelect, Container, FormControl, Grid} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    spacing: 8
  },
});

function App() {
  const [audio, setAudio] = useState(null);
  const [location, setLocation] = useState(null);
  const [locationName, setLocationName] = useState(null);
  const [currentLocationName, setCurrentLocationName] = useState(null);
  const [weather, setWeather] = useState(null);
  const [loadingAudio, setLoadingAudio] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(true);

  const options = [
    { value: '37.233333,-115.808333', label: 'Area 51, USA'},
    { value: '51.178858, -1.826190', label: 'StoneHenge, UK'},
    { value: '40.258454, -109.88787', label: 'Skinwalker Ranch, USA'},
    { value: '34.052235, -118.243683', label: 'Los Angeles, USA' },
    { value: '40.712776, -74.005974', label: 'New York, USA' },
    { value: '51.5074, -0.1278', label: 'London, UK' },
    { value: '31.2304, 121.4737', label: 'Shanghai, China' },
    { value: '-33.8651, 151.2093', label:'Sydney, Australia'},
    { value: '35.682839, 139.759455', label:'Tokyo, Japan'}, 
    { value: '48.8566, 2.3522', label: 'Paris, France'}, 
    { value: '52.5200, 13.4050', label: 'Berlin, Germany'}, 
    { value: '41.9028, 12.4964', label: 'Rome, Italy'}, 
    { value: '40.4168, -3.7038', label: 'Madrid, Spain'}, 
    { value: '43.651070, -79.347015', label: 'Toronto, Canada'}, 
    { value: '19.4326, -99.1332', label: 'Mexico City, Mexico'}, 
    { value: '-22.9068, -43.1729', label: 'Rio de Janeiro, Brazil'}, 
    { value: '30.0444, 31.2357', label: 'Cairo, Egypt'}, 
    { value: '19.0760, 72.8777', label: 'Mumbai, India'}, 
    { value: '1.3521, 103.8198', label: 'Singapore'}, 
    { value: '33.3943, -104.5230', label: 'Roswell, NM, USA'}, 
    { value: '36.4072, -105.5731', label: 'Taos, NM, USA'}, 
    { value: '37.1105, -119.3172', label: 'Shaver Lake, CA, USA'}, 
    { value: '34.0259, -118.7798', label: 'Malibu, CA, USA'}, 
    { value: '39.5296, -119.8138', label: 'Reno, NV, USA'}, 
    { value: '30.2672, -97.7431', label: 'Austin, TX, USA'}, 
    { value: '47.6062, -122.3321', label: 'Seattle, WA, USA'}, 
    { value: '49.2827, -123.1207', label: 'Vancouver, Canada'}, 
    { value: '45.5017, -73.5673', label: 'Montreal, Canada'}, 
    { value: '52.3676, 4.9041', label: 'Amsterdam, Netherlands'}, 
    { value: '48.2082, 16.3738', label: 'Vienna, Austria'}, 
    { value: '47.3769, 8.5417', label: 'Zurich, Switzerland'}, 
    { value: '59.3293, 18.0686', label: 'Stockholm, Sweden'}, 
    { value: '59.9139, 10.7522', label: 'Oslo, Norway'}, 
    { value: '55.6761, 12.5683', label: 'Copenhagen, Denmark'}, 
    { value: '53.3498, -6.2603', label: 'Dublin, Ireland'}, 
    { value: '53.2156,-6.6631', label: 'Naas, Ireland'}, 
    { value: '-37.8136, 144.9631', label: 'Melbourne, Australia'}, 
    { value: '37.5665, 126.9780', label: 'Seoul, South Korea'}, 
    { value: '39.9042, 116.4074', label: 'Beijing, China'}, 
    { value: '13.7563, 100.5018', label: 'Bangkok, Thailand'}, 
    { value: '-1.286389, 36.817223', label: 'Nairobi, Kenya'}, 
    { value: '-33.9249, 18.4241', label: 'Cape Town, South Africa'}, 
    { value: '-34.6118, -58.4173', label: 'Buenos Aires, Argentina'}, 
    { value: '-12.0464, -77.0428', label: 'Lima, Peru'}, 
    { value: '-23.5505, -46.6333', label: 'Sao Paulo, Brazil'}, 
    { value: '-33.4489, -70.6693', label: 'Santiago, Chile'}, 
    { value: '4.6097, -74.0817', label: 'Bogota, Colombia'}, 
  ];


  const fetchSound = async () => {
    setLoadingAudio(true)
    try {

      const response = await axios.get('https://weathersynth.com/generate_sound.php', { params: { coordinates: location} });
      const json_response = JSON.parse(response.data)
      setAudio('https://weathersynth.com/audio/' + json_response.audiofile);
      setWeather(JSON.parse(json_response.location_weather[0]))
      setCurrentLocationName(locationName)
      


    } catch (error) {
      console.error('Error fetching data:', error);
    }

    setLoadingAudio(false)
  };

  return (
    
    <ThemeProvider theme={darkTheme}>
      <CssBaseline enableColorScheme />
    <div>
      <Container>
      <h1>Riley's Weather Synthesizer</h1>
      <p><small>Headphones recommended</small></p>

      <FormControl fullWidth>
      <NativeSelect
        onChange={e => {
          setLocation(e.target.value)
          setLocationName(e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text)
          setButtonDisabled(false)
        }}
      >
        <option value="">Select a city</option>
        {options.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </NativeSelect>
    </FormControl>


      
      <Button 
        sx={{ mt: 2, mb: 2}}
        variant='contained'
        disabled={isButtonDisabled} 
        onClick={fetchSound}>
          Generate
      </Button>


      {loadingAudio && (
      <Grid container justifyContent="center">
        <Grid item>
        <Triangle
          visible={true}
          height="80"
          width="80"
          color="#4fa94d"
          ariaLabel="triangle-loading"
          wrapperStyle={{}}
          wrapperClass=""
          />
        </Grid>
      </Grid>
      )}


      {audio && !loadingAudio && (
        <div>
          <Player src={audio} height={40} />
        </div>
      )}
      {weather && !loadingAudio && (
        <div>
          <h3>Realtime Weather Data for {currentLocationName}</h3>
          Temperature: {weather.temperature} <br />
          Temperature Apparent: {weather.temperature_apparent} <br />
          Wind gust: {weather.wind_gust}<br />
          Wind_direction: {weather.wind_direction}<br />
          Wind_speed: {weather.wind_speed}<br />
          Humidity: {weather.humidity}%<br />
          Visibility: {weather.visibility}<br />
          Dewpoint: {weather.dewpoint}<br />

          Cloud base: {weather.cloudbase}<br />
          Cloud cover: {weather.cloudcover} <br />
          Cloud ceiling: {weather.cloudcover} <br />
          
          Pressure surface level: {weather.pressuresurfacelevel}<br />

          <h3>Synthesizer parameters</h3>
          Frequency - Initial Temperature: {weather.initial_frequency.toFixed(2)}Hz <br />
          Frequency - Apparent Temperature: {weather.apparent_frequency.toFixed(2)}Hz <br />
          Frequency - Dew Point: {weather.dew_point_frequency.toFixed(2)}Hz <br />
          Frequency - Pressure: {weather.pressure_frequency.toFixed(2)}Hz <br />
          Glide Time: {weather.glide_time.toFixed(2)} seconds<br />
          LFO Frequency: {weather.lfo_frequency.toFixed(2)}Hz<br />
          LFO Depth: {weather.lfo_depth}<br />
          LFO2 Frequency: {weather.lfo2_frequency.toFixed(2)}Hz<br />
          LFO2 Depth: {weather.lfo2_depth}<br />
          LFO3 Frequency: {weather.lfo3_frequency.toFixed(2)}Hz<br />
          LFO3 Depth:  {weather.lfo3_depth}<br />
          Delay Time :  {weather.delay_time.toFixed(2)} seconds<br />
          Delay Feedback:  {weather.delay_feedback.toFixed(2)}<br />
          Delay Mix:  {weather.delay_mix.toFixed(2)}<br />
      </div>
      )}
    </Container>
    </div>
    </ThemeProvider>
  );
}

export default App;
